/* Assuming you have a styles.css file imported into your project */
.product-card {
    position: absolute;
    /* Ensure that the button is positioned relative to the card */
}

.cart-button {
    position: absolute;
    /* Position it absolutely within the card */
    top: 2;
    /* Adjust the distance from the top */
    right: 2;
    /* Adjust the distance from the right */
    z-index: 10;
    /* Keep it above other content */
}


.product-image {
    transition: transform 0.3s ease;
    /* Smooth transition for the zoom effect */
}

.product-image:hover {
    transform: scale(1.1);
    /* Scale the image up to 110% on hover */
}

/* Hide scrollbar */
.scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Opera */
}

/* Custom shimmer effect background */
.animate-shimmer {
    background: linear-gradient(to right,
            #f0f0f0 0%,
            #e0e0e0 20%,
            #f0f0f0 40%,
            #f0f0f0 100%);
    background-size: 200% 100%;
}