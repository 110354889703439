/* Hide scrollbar for Chrome, Safari, and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  
  /* Hide scrollbar for IE and Edge */
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  /* Button styling */
  button {
    font-size: 12px; /* Adjust the size of the arrow */
  }
  
  /* Optional: Add a smooth transition for hover effect */
  
  