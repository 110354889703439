/* Shimmer Effect */
@keyframes shimmer {
    0% {
        background-position: -200px 0;
    }

    100% {
        background-position: 200px 0;
    }
}

.banner-shimmer {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
}

/* Image Fade-In Effect */
.banner-image {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.banner-image.loaded {
    opacity: 1;
}