/* Hide the spinner for Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide the spinner for Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}