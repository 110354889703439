.slider-root {
    display: flex;
    align-items: center;
    width: 100%;
    /* Full width for the slider */
    position: relative;
    /* Allows absolute positioning of range */
}

.slider-track {
    background: #ddd;
   
    /* Track background color */
    flex: 1;
    /* Full width */
    height: 6px;
    /* Track height */
    border-radius: 5px;
    /* Rounded corners for track */
}

.slider-range {
    background: #03921e;
    /* Range color */
    position: absolute;
    height: 100%;
    /* Full height of the track */
    left: 0;
    /* Start from the left of the track */
    border-radius: 5px;
    /* Rounded corners for range */
}

.slider-thumb {
    width: 12px;
    /* Thumb width */
    height: 12px;
    /* Thumb height */
    background: white;
    /* Thumb background color */
    border: 2px solid #024c0f;
    /* Border color */
    border-radius: 50%;
    /* Circular thumb */
    cursor: pointer;
    /* Pointer cursor on hover */
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
    /* Thumb shadow */
    transition: background 0.2s ease, transform 0.2s ease;
    /* Smooth transitions */
}

.slider-thumb:hover {
    background: #f0f0f0;
    /* Thumb color on hover */
    transform: scale(1.1);
    /* Slight enlarge on hover */
}

.slider-container {
    position: relative;
    width: 100%;
    /* Full width for the container */
}

.slider-values {
    display: flex;
    justify-content: space-between;
    /* Space values evenly */
    margin-bottom: 8px;
    /* Space below values */
    font-weight: medium;
    /* Prominent text */
    color: #000000;
    /* Text color */
    font-size: 12px;
    /* Font size */
   
    /* Add padding to the values */
}

.filter-content {
    padding: 18px;
    /* Inner padding */
    border: .5px solid #024c0f;
    /* Border color */
    border-radius: 10px;
    /* Rounded corners */
    width: 200px;
    /* Width of the filter content */
}