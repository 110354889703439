

.ais-Hits-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: .2em;
}

.ais-Hits-item img {
  margin-right: .2em;
}

.ais-Hits-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: .2em;
  padding-left: .2rem;
  padding-right: .2rem;
}

.ais-Hits-item img {
  margin-right: 1em;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .ais-Hits-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .ais-Hits-list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: .2em;
      padding-left: .2rem;
      padding-right: .2rem;
      display: grid;
  }
}

@media (max-width: 600px) {
  .ais-Hits-list {
    grid-template-columns: repeat(2, 1fr);
   
      display: grid;
  }
}






.custom-sort-by {
  display: inline-block;
  position: relative;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.custom-sort-by select {
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 8px 50px 8px 10px;
  border-radius: 4px;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  outline: none;
  width: 100%;
}

.custom-sort-by::after {
  content: '▼';
  font-size: 16px;
  color: #333333;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.custom-sort-by select:focus {
  border-color: #66afe9;
  
}

.custom-sort-by select:disabled {
  background-color: #f0f0f0;
  color: #999999;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .custom-sort-by {
    width: 100%;
  }

  .custom-sort-by select {
    width: 100%;
  }
}




/* body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
} */

/* .search-panel {
  display: flex;
  min-height: 100vh;
}

.search-panel__filters {
  width: 350px;
  padding: 20px;
  background-color: #ffffff;
  border-right: 1px solid #ffffff;
  box-sizing: border-box;
}

.search-panel__filters .ais-Panel {
  margin-bottom: 20px;
}

.search-panel__filters .ais-Panel--header {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 10px;
}

.search-panel__results {
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.search-panel__results .ais-Hits-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.search-panel__results .ais-Hits-item {
  margin-bottom: 20px;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 20px;
}

.search-panel__results .ais-Pagination {
  margin-top: 20px;
  text-align: center;
} */



  /* .custom-numeric-menu .ais-NumericMenu-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    transition: background-color 0.2s;
    cursor: pointer;
  }

  .custom-numeric-menu .ais-NumericMenu-item:hover {
    background-color: #f3f4f6;
  }

  .custom-numeric-menu .ais-NumericMenu-item--selected {
    background-color: #3b82f6;
    color: white;
    font-weight: bold;
  } */

  .panel {
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    padding: 1rem;
    background-color: #fff;
    
  }

  .panel:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }

  .custom-numeric-menu .ais-NumericMenu-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    transition: background-color 0.2s;
    cursor: pointer;
  }

  .custom-numeric-menu .ais-NumericMenu-item:hover {
    background-color: #f3f4f6;
  }

  .custom-numeric-menu .ais-NumericMenu-item--selected {
    background-color: #035a1b;
    color: white;
    font-weight: bold;
  }

  
.custom-numeric-menu .ais-NumericMenu-item {
    margin-bottom: 10px; /* Adjust this value as needed */
}


.filter-panel {
  width: 250px;
  padding: 30px;
  border-radius: 1px;
  z-index: auto;
overflow: auto;


}

@media (max-width: 768px) {

  /* Adjust the width as needed */
  .filter-panel {
    z-index: 20;
    /* Or use display: none if you want it hidden completely */
  }
}

.filter-section {
    margin-bottom: 20px;
}

.filter-heading {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.filter-content {
    padding: 18px;
    border: .5px solid #024c0f;
    border-radius: 10px;
     width: 200px;
    
}

.custom-numeric-menu {
    margin-top: 10px;
}




@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.shimmer {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}
/* General Styles */

/* .overlay {
  z-index: 9998;
} */




.filter-content label {
  font-weight: bold; /* Example */
}
.filter-content input[type="radio"] {
  margin-right: 10px; /* Adjust spacing as needed */
}

.custom-refinement-list .ais-RefinementList-item {
  display: flex; /* Make the refinement list item a flex container */
  align-items: center; /* Align checkbox and label vertically */
}

.custom-refinement-list .ais-RefinementList-item input[type="checkbox"] {
  margin-right: 10px; /* Adjust spacing here */

  /* New styles for checkbox color customization */

  width: 14px; /* Set desired checkbox size */
  height: 14px;
}

.custom-refinement-list .ais-RefinementList-item label {
  display: inline-block; /* Make the label behave like an inline block */
  margin-right: 0; /* Remove default margin from label */
}



.custom-dropdown {
  /* Styles for the wrapper element */
  position: relative; /* Required for positioning the custom arrow */
  display: inline-block; /* Adjust display as needed */
  width: 150px; /* Set desired width */
  border: 1px solid #ccc; /* Add a border */
  border-radius: 5px; /* Add rounded corners */
  padding: 5px 10px; /* Add padding */
  outline: none; /* Remove default outline */
}

.custom-dropdown::after {
  /* Styles for the custom arrow */
 
  display: block;
  position: absolute;
  top: 50%; /* Center vertically */
  right: 10px; /* Position right of the dropdown */
  transform: translateY(-50%); /* Adjust vertical positioning if needed */
  width: 0;
  height: 0;
  border-left: 5px solid transparent; /* Left border for arrow */
  border-right: 5px solid transparent; /* Right border for arrow */
  border-bottom: 8px solid #ccc; /* Bottom border for arrow */
}

.custom-dropdown.ais-SortBy-dropdown--active::after {
  /* Style the arrow when the dropdown is open */
  transform: translateY(-50%) rotate(180deg); /* Rotate arrow down */
}

/* Optional (Limited Control): Styles for the options list */
.ais-SortBy-option {
  /* Adjust styles for the options */
  color: #333; /* Set text color */
  background-color: #fff; /* Set background color */
  padding: 5px; /* Add padding */
  cursor: pointer; /* Set cursor to pointer on hover */
}










.custom-category {
  position: relative;
 
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem 0.5rem 0.5rem;

  background-color: #22543d;

  border-radius: 0.375rem;

  color: #ffffff;
  margin-top: 0.5rem;
  gap: 0.5rem;
}

.custom-category-label {
  font-size: 0.775rem;

  font-weight: 300;
}

.custom-delete-button {
  position: absolute;
  right: 0.5rem;

  top: 50%;
  transform: translateY(-50%);

  background-color: transparent;
 
  color: #000000;
  font-size: 0.75rem;
 
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.custom-delete-button:hover {
  background-color: transparent;
  
}



/* Custom checkbox styles */
.custom-checkbox {
  display: none;
  /* Hide the default checkbox */
}

.custom-checkbox+label {
  position: relative;
  padding-left: 30px;
  /* Space for custom checkbox */
  cursor: pointer;
  user-select: none;
}

.custom-checkbox+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  /* Width of the custom checkbox */
  height: 20px;
  /* Height of the custom checkbox */
  border: 2px solid #881337;
  /* Border color */
  border-radius: 4px;
  /* Rounded corners */
  background-color: white;
  /* Background color */
  transition: background-color 0.3s, border-color 0.3s;
}

/* Checked state */
.custom-checkbox:checked+label:before {
  background-color: #881337;
  /* Change background on check */
  border-color: #881337;
  /* Change border color on check */
}

.custom-checkbox:checked+label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 10px;
  width: 5px;
  height: 10px;
  border: solid white;
  /* Checkmark color */
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  /* Checkmark rotation */
}


/* Pagination Container */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* Previous and Next Buttons */
.pagination-button {
  padding: 10px 20px;
  background-color: #e0e0e0;
  color: #4f4f4f;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 0 10px;
}

.pagination-button:hover {
  background-color: #c1c1c1;
  color: #333;
}

.pagination-button.disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

/* Page Numbers */
.pagination-pages {
  display: flex;
  gap: 5px;
}

.pagination-page {
  padding: 10px 20px;
  background-color: #f0f0f0;
  color: #4f4f4f;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-page:hover {
  background-color: #ddd;
}

.pagination-page.active {
  background-color: #007bff;
  color: white;
}